import {
	DESKTOP_SM_BP,
	MOBILE_L_BP,
	TABLET_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

const COLOR_WHITE = "var(--primary-neutral-white)";

export const BragBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: ${({ isPaid }) => (isPaid ? "32" : "80")}px 0;
	.grid-container {
		width: 100%;
	}
	.brag-bar-col-wrapper {
		display: flex;
		flex-direction: column;
		row-gap: ${({ hasDescription }) => (hasDescription ? "1.5rem" : "2.5rem")};
		.desc {
			color: ${({ darkBg }) =>
				!darkBg
					? "var(--primary-neutral-nightshade-600)"
					: "var(--theme-light-font-color, var(--primary-green-400))"};
			font-weight: ${({ isPaid }) => (isPaid ? 500 : 400)};
		}
	}
	.logo-wrapper > div > div {
		align-items: center;
	}
	.logo-wrapper img {
		max-height: 30px;
	}
	.heading {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		.label {
			color: ${({ darkBg }) =>
				!darkBg
					? "var(--secondary-gray-dark)"
					: `var(--theme-secondary-light, ${COLOR_WHITE})`};
		}
		.headline {
			color: ${({ darkBg }) =>
				!darkBg ? "var(--primary-neutral-nightshade-800)" : COLOR_WHITE};
		}
	}

	.logos {
		display: flex;
		flex-direction: column;
		align-items: center;
		${({ hasDescription }) => hasDescription && `padding-top: 16px;`}
	}

	//implemented from existing Investors component in b2c block
	.badge-wrapper {
		display: flex;
		justify-content: center;
		flex-flow: nowrap;
		gap: 3.5rem;
		.container {
			width: 227px;
			.description {
				text-align: center;
				padding-top: 18px;
				padding-left: 26px;
				padding-right: 26px;
				.name {
					color: "var(--primary-neutral-nightshade-600)";
					padding-bottom: 8px;
				}
				.designation {
					color: "var(--primary-neutral-nightshade-600)";
				}
			}
			.image {
				picture {
					img {
						width: 100% !important;
					}
				}
			}
		}
	}
	.badge-wrapper-1 {
		overflow: scroll;
	}
	.star-rating-col-wrapper {
		display: flex;
		justify-content: center;
		text-align: center;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		.container {
			margin-left: 7px !important;
			margin-right: 7px !important;
		}
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.brag-bar-col-wrapper {
			row-gap: 18px;
		}
		.grid-container {
			width: inherit;
		}
		.logosAndText {
			gap: 1.5rem;
		}

		.logo-wrapper > div {
			width: fit-content;
		}
		.badge-wrapper-1 {
			overflow: none;
			.badge-wrapper {
				flex-flow: wrap;
				width: fit-content;
				gap: 0.5rem;
			}
		}
	}

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		.container {
			width: 158px !important;
			margin-left: 4px !important;
			margin-right: 4px !important;
			.description {
				height: 85px !important;
				padding: 7px 10px 6px 10px !important;
			}
		}
	}
`;
