import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import {
	HorizontalList,
	Text,
	CloudinaryImage,
	Grid,
	Col,
	Image,
	Link,
	StarRating,
} from "@website-builder/ui/shared/elements";

import { BragBarWrapper } from "./styles";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const BragBar = ({
	label,
	headline,
	description,
	logos,
	maxLogosInRow,
	hasDarkBackground,
	reportBadges,
	isPaid,
	pageSection,
	starRating,
}) => {
	let logoItems = [];
	logos?.forEach((logo, i) => {
		if (logo?.imageUrl) {
			let logoItem = <></>;
			if (logo?.cta) {
				logoItem = (
					<Link
						key={`logo-image-link-${i}`}
						href={logo.cta.link?.link}
						target={logo.cta.link?.target}
						onClick={(e) =>
							handleCTAClick(
								logo.cta,
								{
									page_section: pageSection,
									redirect_to: logo.cta.link?.link,
								},
								{},
								e,
							)
						}
						aria-label={`${logo?.alt} link` || `brand link${index + 1}`}
					>
						<CloudinaryImage
							className="social-logo-img"
							url={logo?.imageUrl}
							alt={logo?.alt || `brand logo${i + 1}`}
							quality="auto"
							lazyload
							fetchFormat="auto"
							sizes={{
								default: {
									height: 40,
								},
							}}
						/>
					</Link>
				);
			} else {
				logoItem = (
					<Image
						key={`logo-image-${i}`}
						src={logo?.imageUrl}
						alt={logo?.alt}
						srcBase64={logo?.base64}
						quality="auto"
						lazyload
						fetchFormat="auto"
						sizes={{
							default: {
								height: 40,
							},
						}}
					/>
				);
			}
			logoItems.push(logoItem);
		}
	});

	return (
		<BragBarWrapper
			isPaid={isPaid}
			darkBg={hasDarkBackground}
			hasDescription={!!(label || headline || description)}
		>
			<Grid className="grid-container">
				{label || headline ? (
					<Col
						start={3}
						span={8}
						mdStart={1}
						mdSpan={6}
						className="brag-bar-col-wrapper"
					>
						<div className="heading">
							{label ? (
								<Text className="label" variant="label_M">
									{label}
								</Text>
							) : null}
							{headline ? (
								<Text className="headline" variant="headline_3" tag="h2">
									{headline}
								</Text>
							) : null}
						</div>
					</Col>
				) : null}
				{description ? (
					<Col
						start={4}
						span={6}
						mdStart={2}
						mdSpan={4}
						className="brag-bar-col-wrapper"
					>
						<Text className="desc" variant="paragraph_L">
							{description}
						</Text>
					</Col>
				) : null}

				{starRating && Object.keys(starRating).length > 0 && (
					<Col
						start={4}
						span={6}
						mdStart={2}
						mdSpan={4}
						className="star-rating-col-wrapper"
					>
						<StarRating
							starRatingDetails={starRating}
							hasDarkBackground={hasDarkBackground}
							blockId={starRating?._uid}
						></StarRating>
					</Col>
				)}

				{logos.length || reportBadges.length ? (
					<Col
						start={2}
						span={10}
						mdStart={1}
						mdSpan={6}
						className="brag-bar-col-wrapper"
					>
						<div className="logos">
							{logos.length > 0 ? (
								<div className="logo-wrapper">
									<HorizontalList
										listItems={logoItems}
										maxItems={maxLogosInRow}
										gap="30px"
										centered={true}
									/>
								</div>
							) : null}
							{reportBadges.length > 0 ? (
								<div className="badge-wrapper-1">
									<div className="badge-wrapper">
										{reportBadges.map((badge, index) => (
											<div key={index} className="container">
												<div className="image">
													<CloudinaryImage
														lazyload
														url={badge?.image || badge?.imageUrl}
														alt={badge?.altTag}
													/>
												</div>
												<div className="description">
													<Text variant="paragraph_M_bold" className="name">
														{badge.heading}
													</Text>
													<Text className="designation">
														{badge.description}
													</Text>
												</div>
											</div>
										))}
									</div>
								</div>
							) : null}
						</div>
					</Col>
				) : null}
			</Grid>
		</BragBarWrapper>
	);
};

BragBar.propTypes = {
	// label: PropTypes.string,
	headline: PropTypes.string,
	description: PropTypes.string,
	logos: PropTypes.array,
	maxLogosInRow: PropTypes.number,
	hasDarkBackground: PropTypes.bool,
	reportBadges: PropTypes.array,
};

BragBar.defaultProps = {
	label: "",
	headline: "",
	description: "",
	logos: [],
	maxLogosInRow: 0,
	hasDarkBackground: false,
	reportBadges: [],
};

export default BragBar;
